import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import { fadeIn } from "react-animations"

const Banner = ({ heading, subHeading, trail, trailLink, image, children }) => {
    return (
        <div className="w-screen h-md md:h-3xl">
            <BannerWrapper>
                <img src={image} className="banner"></img>
                <GrayOverlay>
                    <HeadingWrapper>
                        {subHeading &&
                            <SubHeading>
                                <div className="text-sm md:text-md">
                                    {subHeading}
                                </div>
                            </SubHeading>
                        }
                        {heading &&
                            <Heading>
                                <div className="py-1 text-lg leading-normal md:py-8 md:text-6xl md:leading-10">
                                    {heading}
                                </div>
                            </Heading>
                        }
                        {trail &&
                            <TrailWrapper className="text-sm md: text-sm">
                                <Trail>
                                    <Link to="/"><TrailLink>Home</TrailLink></Link>
                                </Trail>
                                <Trail>/</Trail>
                                <Trail>
                                    <   Link to={trailLink || "/"}><TrailLink>{trail || "Home"}</TrailLink></Link>
                                </Trail>
                            </TrailWrapper>
                        }
                    </HeadingWrapper>
                </GrayOverlay>
            </BannerWrapper>
        </div>
    );
}

export default Banner;

export const BannerMultipleImages = ({ banners }) => {
    let index = -1;
    const autoPlaySpeed = 6000;
    const headingMaxMargin = 100;
    const headingMinMargin = 0;
    const [topBanner, setTopBanner] = useState(banners[0]);
    const [bottomBanner, setBottomBanner] = useState(banners.length > 1 ? banners[1] : null);
    const [headingMargin, setHeadingMargin] = useState(headingMaxMargin);
    const [headingOpacity, setHeadingOpacity] = useState(0);
    const [topOpacity, setTopOpacity] = useState(0);
    const [bottomOpacity, setBottomOpacity] = useState(0);

    useEffect(() => {
        let timeoutNextBannerAppear, timeoutBannerAppear, timeoutNextBanner;
        if (index === -1) {
            index = 0;
            setTopOpacity(1);
            setHeadingOpacity(1);
            setHeadingMargin(headingMinMargin);

            timeoutNextBannerAppear = setTimeout(function () {
                setBottomOpacity(1);
            }, 3000);
        }
        const autoLoop = setInterval(() => {
            index = (index + 1) % banners.length;
            const nextIndex = (index + 1) % banners.length;

            setTopOpacity(0);
            setHeadingOpacity(0);
            setHeadingMargin(headingMaxMargin);

            timeoutBannerAppear = setTimeout(function () {
                setTopBanner(banners[index]);
                setTopOpacity(1);
                setHeadingOpacity(1);
                setHeadingMargin(headingMinMargin);
            }, 2000);

            timeoutNextBanner = setTimeout(function () {
                setBottomBanner(banners[nextIndex]);
            }, 3000);
        }, autoPlaySpeed);
        return () => {
            clearInterval(autoLoop);
            if (timeoutNextBannerAppear) clearTimeout(timeoutNextBannerAppear);
            if (timeoutBannerAppear) clearTimeout(timeoutBannerAppear);
            if (timeoutNextBanner) clearTimeout(timeoutNextBanner);
        }
    }, []);

    return (
        <div className="relative w-screen h-md text-lg md:h-3xl md:text-4xl">
            <BottomImageBannerWrapper style={{ opacity: bottomOpacity }}>
                <img src={bottomBanner.image} draggable={false} className="banner"></img>
            </BottomImageBannerWrapper>
            <TopImageBannerWrapper style={{ opacity: topOpacity }}>
                <img src={topBanner.image} draggable={false} className="banner"></img>
                <MovingHeading className="text-lg md:text-4xl pt-2 md:pt-6 px-4 md:px-32" style={{ marginTop: headingMargin, opacity: headingOpacity }}>
                    {topBanner.heading}
                </MovingHeading>
            </TopImageBannerWrapper>
        </div>
    );
}

const fadeInAnimation = keyframes`${fadeIn}`;

const BannerWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    animation: 1s ${fadeInAnimation};
`;

const GrayOverlay = styled.div`
    display: flex;
    flex: 1;
    background-color: rgba(0,0,0,0.25);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    overflow: auto;
    opacity: 1;
`;

const HeadingWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
`;

const SubHeading = styled.div`
    font-family: Ubuntu;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0;
    text-transform: uppercase;
`;

const Heading = styled.div`
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    letter-spacing: 1.5px;
    color: #ffffff;
    font-family: Raleway;
`;

const TrailWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const TrailLink = styled.div`
    color: #f3f3f3;
    &:hover {
        color: #368AEA;
    }
`;

const Trail = styled.span`
    color: #f3f3f3;
    margin: 0px 5px;
`;

const BottomImageBannerWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    transition: opacity 1s ease 0s;
`;

const TopImageBannerWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    transition: opacity 1s ease 0s;
`;

const MovingHeading = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    font-weight: 700;
    color: #FFFFFF;
    text-shadow: 0px 0px 6px #368AEA, 0 0 10px #368AEA;
    opacity: 1;
    position: absolute;
    transition: opacity 1s ease 0s, margin-top 1s ease 0s;
`;